import React from 'react'
import { useInView } from 'react-intersection-observer'

const YearList = ({ year, posts }) => {
  const { ref, inView } = useInView({
    threshold: 0,
    triggerOnce: true
  })

  const yearClassName = inView ? `year-section animate-in year-${year}` : `year-section year-${year}`

  return (
    <section className={yearClassName} key={`${year}-section`} ref={ref}>
        <h3>{year}</h3>
        <ol>
          {posts}
        </ol>
    </section>
  )
}

export default YearList