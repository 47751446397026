import React from 'react'
import { graphql } from "gatsby"

import ArchivePost from "../components/ArchivePost"
import YearList from "../components/YearList"
import MetaData from "../components/MetaData"

export const Head = (props) => <MetaData title="Archives" description="A list of all my posts sorted by most recent." {...props} />

const Archive = ({ data, location, ...rest }) => {
  const { nodes: posts } = data.allMarkdownRemark

  const years = {}

  // Posts by year
  posts.forEach(function(post) {
    const year = post.frontmatter.year;

    // Create entry for it if it doesn't exist
    if(undefined === years[year]) {
      years[year] = []
    }

    years[year].push(post)
  })

  const postList = []
  
  Object.keys(years).reverse().forEach(function(year) {
    const posts = years[year].reverse().map(post => {
      const title = post.frontmatter.title || post.fields.slug
    
      return (
        <ArchivePost data={data} location={location} post={post} key={title} title={title} />
      )
    })

    const yearList = (
      <YearList year={year} posts={posts} key={year} />
    )

    postList.push(yearList)
  });

  return (
    <>
      <noscript>
        <style type="text/css" dangerouslySetInnerHTML={{__html: `.archives h3, .archives .post-list-item { opacity: 1 }`}}></style>
      </noscript>
      {postList}
    </>
  )
}

export default Archive

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      filter: { frontmatter: { status: { ne: "draft" } } }
      sort: { fields: [frontmatter___date], order: ASC }
    ) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM D")
          year: date(formatString: "YYYY")
          title
          description
        }
        timeToRead
      }
    }
  }
`
