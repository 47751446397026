import React from 'react'
import { useInView } from 'react-intersection-observer'
import { Link } from "gatsby"
import PostInfo from "./PostInfo"

const ArchivePost = ({ data, location, post, title }) => {
  const { ref, inView } = useInView({
    threshold: 0,
    triggerOnce: true
  })

  return (
    <li key={post.fields.slug}>
      <article
        className={inView ? `animate-in post-list-item` : `post-list-item`}
        itemScope
        ref={ref}
        itemType="http://schema.org/Article"
      >
        <Link state={{ prevPath: location.href }} to={post.fields.slug} itemProp="url">
        <PostInfo date={post.frontmatter.date} timeToRead={post.timeToRead} />
          <header>
            <h2>
              <span itemProp="headline">{title}</span>
            </h2>
            <p class="description">{post.frontmatter.description}</p>
          </header>
        </Link>
      </article>
    </li>
  )
}

export default ArchivePost