import React from 'react'
import dayjs from 'dayjs'

const PostInfo = ({ date, timeToRead }) => {
  const postDate = dayjs(date)

  return (
    <p className="post-info">
      <time dateTime={postDate.format('YYYY-MM-DD')}>{date}</time>
      <svg width='8' height='13' viewBox='0 0 8 13'>
        <polygon fill='currentColor' opacity=".9" points='4 0 8 6.286 4 12.571 0 6.286' transform='translate(.044)'/>
      </svg>
      <span>{Math.round(timeToRead * 1.3)} minute read</span>
    </p>
  )
}



export default PostInfo
